<template>
  <div>
    <vue-headful :title="pageTitle" />

    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">
        {{ pageheading.toLocaleUpperCase() }}
      </h1>
    </div>

    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area">
        <div v-if="SiteNavigation" id="pagelayout">
          <!-- The buttons for adding / removing Menu Items. -->
          <div id="buttongroup" style="margin-bottom:1rem; display: flex; justify-content: flex-end!important;">
              <div class="level">
                <div class="level-left">
                    <div class="field is-grouped">
                      <div v-if="selected.length < 2" class="control">
                          <!-- :disabled="isDragEnabled" -->
                          <a :class="['button is-accent', !$hasPermissions(clientSession, ['CLIENT_MENU'], 2) ? 'disabled' : '']">
                            <span class="icon">
                              <i class="fal fa-plus-square"></i>
                            </span>
                            <span>
                                <router-link :to="{ name: 'newMenuItem', params: { menuItem: selected.length === 1 ? selected[0] : {}, reportTypes:reportTypes }}" class="has-text-white">
                                  {{ selected.length === 0 ? 'Add Menu Item' : 'Add Menu Item From Item' }}
                                </router-link>
                            </span>
                          </a>
                      </div>
                      <div class="control" style="display: none;">
                          <a :class="['button is-dark', selected.length === 0 ? 'disabled' : '']" @click="confirmDelete" :disabled="selected.length === 0">
                            <span class="icon">
                                <i class="fal fa-trash-alt"></i>
                            </span>
                            <span>Delete Report Type{{ selected.length > 1 ? 's' : '' }}</span>
                          </a>
                      </div>
                      <div class="control" v-if="hasChangedOrder">
                          <a :class="['button is-danger']" @click="setupSiteNavigation">
                            <span class="icon">
                                <i class="fal fa-times"></i>
                            </span>
                            <span>Cancel</span>
                          </a>
                      </div>
                      <div class="control" v-if="hasChangedOrder">
                          <a :class="['button is-accent']" @click="saveNewOrder">
                            <span class="icon">
                                <i class="fal fa-check"></i>
                            </span>
                            <span>Save New Order</span>
                          </a>
                      </div>
                    </div>
                </div>
              </div>
          </div>
          <div class="menuItemEditor" style="padding-bottom: 1rem;">
            <div class="menu-container">
              <div class="menuItemRow menuItemRow--header">
                <div class="menuItem cms">
                  Label
                </div>
                <div class="menuItemType cms">
                  ItemType
                </div>
                <div class="menuItemGroupedByYear cms">
                  Grouped By Year
                </div>
                <div class="menuItemOrder cms">
                  Order
                </div>
                <div class="menuItemActions cms">
                  Actions
                </div>
              </div>
            </div>
            <ClientMenuManagementRow class="menu-container" v-for="menuItem in SiteNavigation.filter(item => parseInt(item.Depth) === 0)" :key="menuItem.Id" :hasChangedOrder="hasChangedOrder" :SiteNavigation="SiteNavigation" :menuItem="menuItem" :reportTypes="reportTypes" @SiteNavigationDeleted="SiteNavigationDeleted" @SiteNavigationUpdated="updateSiteNavigation"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import ClientMenuManagementRow from './partials/ClientMenuManagmentRow.vue'

let pageName = 'Client Menu Management'

export default {
  components: {
    ClientMenuManagementRow
  },
  data () {
    return {
      isLoading: false,
      pageheading: pageName,
      selected: [],
      reportTypes: [],
      depth: 0,
      SiteNavigation: [],
      hasChangedOrder: false
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    }
  },
  created () {
    this.getSiteNavigationFromAPI()
    this.getReportTypesFromAPI()
  },
  methods: {
    updateSiteNavigation (event) {
      this.SiteNavigation = event.SiteNavigation.sort((a, b) => parseInt(a.Order) - parseInt(b.Order))
      this.hasChangedOrder = true
      this.$forceUpdate()
    },
    SiteNavigationDeleted (event) {
      this.getSiteNavigationFromAPI()
    },
    async getSiteNavigationFromAPI () {
      await this.$store.dispatch('updateActiveSite')
      this.setupSiteNavigation()
    },
    async saveNewOrder () {
      let structure = []
      this.SiteNavigation.forEach(function (navItem) {
        let object = {}

        // Deep clone and prune
        Object.keys(navItem).forEach(function (key) {
          if (key !== 'children') {
            object[key] = navItem[key]
          }
        })
        structure.push(object)
      })

      let response = await this.axios.put('sites/menu/order', structure)
      if (response.status === 204) {
        this.hasChangedOrder = false
        await this.getSiteNavigationFromAPI()
      } else {
        this.errorToast('Failed to save order.')
      }
    },
    setupSiteNavigation () {
      let that = this
      let records = []

      // Iterate and figure out the relative depth of the menuItem. 1 is top level.
      Array.from(JSON.parse(this.$store.state.activeSite.siteNavigation)).forEach(function (menuItem) {
        records.push(menuItem)
      })

      // Make sure we sort by id....
      this.SiteNavigation = records.sort((a, b) => {
        return parseInt(a.Order) - parseInt(b.Order)
      })

      records = []

      this.SiteNavigation.forEach(function (menuItem) {
        that.depth = 0
        that.findParent(menuItem)
        that.findChildren(menuItem)
        menuItem.Depth = that.depth
        records.push(menuItem)
      })

      // Make sure we sort by id....
      this.SiteNavigation = records.sort((a, b) => {
        return parseInt(a.Order) - parseInt(b.Order)
      })

      this.hasChangedOrder = false
    },
    findChildren (item) {
      let that = this

      item.children = []

      this.SiteNavigation.forEach(function (navItem) {
        if (navItem.ParentId === item.Id) {
          item.children.push(navItem)
        }
      })

      item.children.forEach(function (navItem) {
        that.findChildren(navItem)
      })
    },
    findParent (parent) {
      let that = this
      this.SiteNavigation.forEach(function (navItem) {
        if (parseInt(navItem.Id) === parseInt(parent.ParentId)) {
          that.depth++
          that.findParent(navItem)
        }
      })
    },
    async getReportTypesFromAPI () {
      let response = await this.axios.get('/report-types')

      if (response.status === 200) {
        this.reportTypes = response.data.records
      }
    },
    getLabel (menuItem) {
      if (menuItem.ItemType === 1) {
        return menuItem.Label
      } else {
        let reportType = this.reportTypes.filter(function (type) {
          return type.code === menuItem.ReportCode
        })

        if (reportType.length === 1) {
          return `(${reportType[0].name})`
        }

        return '(N/A)'
      }
    }
  }
}
</script>

<style scoped>
i.fa {
  margin-right: 0px!important;
}

i.fa, i.fas {
    max-width: 50px!important;
    font-size: 1.5rem !important;
    padding: 0px !important;
}

.cms {
  background-color: #f5f5f5;
  font-size: 14px!important;
  font-weight: 600!important;
  color: #595959!important;

  font-family: "Poppins", Helvetica, sans-serif;;
  font-size: 14px;
}

/* Page Layout */
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}

.k-widget k-dateinput k-state-default k-label {
  font-weight: 600 !important;
  padding: 8px;
}

.k-checkbox:checked {
  background-color: #7fb942;
  color: white;
  border-color: #7fb942;
}

.k-pager-numbers .k-state-selected {
  background-color: #7fb942;
  color: white;
  border-color: #7fb942;
}

.k-list .k-item.k-state-selected {
  background-color: #7fb942;
}

.k-list .k-item.k-state-selected:hover {
  background-color: #cbe3b3;
  color: #0d0c0c;
}

.k-date-picker {
  width: 90%;
  margin-right: 6px;
  border: pink;
}

a.download,
a.bcc-color {
  color: #7fb942 !important;
}

.page-subtitle {
  color: #7fb942;
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 8px;
}

.k-invalid-msg {
  display: inline-block;
}

.invalid {
  color: red;
}

.valid {
  color: green;
}

.bcc-color {
  color: #7fb942;
}

.placeholder {
  outline-style: dashed;
  outline-width: 1px;
  outline-color: #7fb942;
}

</style>
