<template>
<div>
  <div class="menuItemRow">
    <div class="menuItem">
      <i :class="getIcon(menuItem)" :style="{
        marginLeft: menuItem.Depth * 2.5 + 'rem',
        marginRight: '.5rem'
      }"></i>
      <router-link :to="{ name: 'EditMenuItem', params: { id: menuItem.Id, menuItem: menuItem, reportTypes:reportTypes }}">
        {{ getLabel() }}
      </router-link>
    </div>
    <div class="menuItemType">
      {{ getType() }}
    </div>
    <div class="menuItemGroupedByYear">
      {{ isDocumentsType(menuItem) ? (menuItem.isPersistent ? 'No' : 'Yes') : '' }}
    </div>
    <div class="menuItemOrder">
      {{menuItem.Order}}
    </div>
    <div class="menuItemActions">
      <div v-if="$hasPermissions(clientSession, ['CLIENT_MENU'], 2)">
        <button @click="move(-1)" v-tooltip.left-start="{ content: 'Move Item Up', container: false, classes: ['tooltip'] }">
          <i class="fa fa-arrow-up"></i>
        </button>
        <button @click="move(1)" v-tooltip.left-start="{ content: 'Move Item Down', container: false, classes: ['tooltip'] }">
          <i class="fa fa-arrow-down"></i>
        </button>
        <button :disabled="!$hasPermissions(clientSession, ['CLIENT_MENU'], 2)" :class="['trash', !$hasPermissions(clientSession, ['CLIENT_MENU'], 2) ? 'disabled' : '']" @click="remove" v-tooltip.left-start="{ content: 'Remove Item', container: false, classes: ['tooltip'] }">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
  </div>
  <ClientMenuManagementRow class="menu-container" v-for="child in menuItem.children.sort((a, b) => parseInt(a.Order) - parseInt(b.Order))" :hasChangedOrder="hasChangedOrder" :key="child.Id" :SiteNavigation="navigation" :menuItem="child" :reportTypes="reportTypes" @SiteNavigationDeleted="SiteNavigationDeleted" @SiteNavigationUpdated="updateSiteNavigation"/>

  <!-- Delete modal: // TODO [BD|05-2021] Wire this up to prompt the user to delete the items -->
  <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
    <div class="modal-background" @click="showDeleteModal = false"></div>
      <div class="modal-card">
      <header class="modal-card-head has-bg-danger">
          <div class="modal-card-title has-bg-danger">Confirm Delete</div>
          <a class="delete" aria-label="close" @click="showDeleteModal = false"></a>
      </header>
      <section class="modal-card-body">
          <div class="content">
          Type "<b>delete</b>" to confirm that you want to delete "{{ getLabel(menuItem) }}"
          </div>
          <div class="field">
          <div class="control">
              <input
              type="text"
              class="input"
              placeholder="type `delete` and then click confirm"
              v-focus
              v-model.trim="confirmationText"
              @keydown.enter.prevent="deleteIfConfirmed"
              />
          </div>
          </div>
      </section>
      <footer class="modal-card-foot">
          <a
          class="button is-danger"
          @click="deleteIfConfirmed"
          :disabled="confirmationText !== 'delete'"
          >
          <span class="icon">
              <i class="fal fa-trash-alt"></i>
          </span>
          <span>Confirm</span>
          </a>
          <a class="button" @click="showDeleteModal = false">Cancel</a>
      </footer>
      </div>
  </div>
</div>
</template>

<script>
import Constants from '../../constants.js'
import Router from 'vue-router'
import Vue from 'vue'
import { mapState } from 'vuex'

Vue.use(Router)

export default {
  name: 'ClientMenuManagementRow',
  props: {
    menuItem: Object,
    reportTypes: Array,
    SiteNavigation: Array,
    hasChangedOrder: Boolean
  },
  data () {
    return {
      Constants: Constants,
      showDeleteModal: false,
      confirmationText: '',
      navigation: []
    }
  },
  created () {
    this.navigation = this.SiteNavigation
  },
  computed: {
    ...mapState(['clientSession'])
  },
  methods: {
    getType () {
      if (this.menuItem.ItemType === 1) {
        return 'Item Grouping'
      } else if (this.menuItem.ItemType === 2) {
        return 'Documents List'
      } else if (this.menuItem.ItemType === 3) {
        return 'Page Destination'
      } else if (this.menuItem.ItemType === 4) {
        return 'Carrier Grouping'
      } else if (this.menuItem.ItemType === 6) {
        return 'Vendor Grouping'
      }
    },
    remove () {
      this.showDeleteModal = true
    },
    getIcon (menuItem) {
      return menuItem.IconType ? Constants.menu.icon[menuItem.IconType].value : ''
    },
    isDocumentsType (menuItem) {
      return parseInt(menuItem.ItemType) === 2
    },
    getLabel () {
      let that = this

      if (this.menuItem.ItemType === 1 || this.menuItem.ItemType === 3) {
        return this.menuItem.Label
      } else {
        if (this.menuItem.ReportCode) {
          let name = ''
          this.reportTypes.forEach(function (_type) {
            if (_type.code.toLowerCase() === that.menuItem.ReportCode.toLowerCase()) {
              name = _type.name
            }
          })

          if (name !== '') {
            return name
          } else {
            return '(N/A)'
          }
        }

        return this.menuItem.Label
      }
    },
    move (direction) {
      let item = this.menuItem
      let tempNavigation = []

      let isMovingUpward = parseInt(direction) === -1
      let itemId = parseInt(item.Id)
      let itemOrder = parseInt(item.Order)
      let itemDepth = parseInt(item.Depth)
      let ParentId = parseInt(item.ParentId)

      // Move the specified item
      this.navigation.sort((a, b) => { return a.Order - b.Order }).forEach(function (navItem) {
        let navItemId = parseInt(navItem.Id)
        let navItemOrder = parseInt(navItem.Order)
        let navItemDepth = parseInt(navItem.Depth)
        let NavItemParentId = parseInt(navItem.ParentId)

        // Make sure they share the same parent
        if (NavItemParentId === ParentId) {
          if (isMovingUpward && itemDepth === navItemDepth && itemOrder > 1) {
            // if navitem is the currently moving item
            if (navItemId === itemId && (navItemOrder - 1) < itemOrder) {
              navItem.Order = (navItemOrder - 1)
            } else if (navItemId !== itemId && navItemOrder === itemOrder - 1) {
              navItem.Order = (navItemOrder + 1)
            }
          } else if (itemDepth === navItemDepth) {
            // if navitem is the currently moving item
            if (navItemId === itemId && (navItemOrder + 1) > itemOrder) {
              navItem.Order = (navItemOrder + 1)
            } else if (navItemId !== itemId && navItemOrder === itemOrder + 1) {
              navItem.Order = (navItemOrder - 1)
            }
          }
        }

        tempNavigation.push(navItem)
      })

      this.commit(tempNavigation, item.Depth)
    },
    commit (tempNavigation, depth) {
      this.navigation = tempNavigation.sort((a, b) => { return a.order - b.order })

      this.$emit('SiteNavigationUpdated', {
        SiteNavigation: this.navigation
      })
    },
    SiteNavigationDeleted () {
      this.$emit('SiteNavigationDeleted')
    },
    async deleteIfConfirmed () {
      if (this.confirmationText === 'delete') {
        let response = await this.axios.delete('/sites/menu/' + this.menuItem.Id)

        if (response.status >= 200 && response.status < 300) {
          this.showDeleteModal = false
          this.currentItem = null

          this.$emit('SiteNavigationDeleted')
        }
      }
    },
    updateSiteNavigation (event) {
      this.navigation = event.SiteNavigation.sort((a, b) => parseInt(a.Order) - parseInt(b.Order))
      this.$emit('SiteNavigationUpdated', {
        SiteNavigation: this.navigation
      })
    }
  }
}
</script>

<style scoped>

.menuItemRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.menuItemEditor .menu-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin: auto auto;
}

.menuItem {
  width: 52.5%;
  padding: .3rem .5rem;
  border: 1px solid #dedede;
}

.menuItemType {
  width: 15%;
  padding: .3rem .5rem;
  border: 1px solid #dedede;
}

.menuItemGroupedByYear {
  width: 15%;
  padding: .3rem .5rem;
  border: 1px solid #dedede;
}

.menuItemOrder {
  width: 5%;
  padding: .3rem .5rem;
  border: 1px solid #dedede;
}

.menu-container .menuItemRow .menuItemType,
.menu-container .menuItemRow .menuItemGroupedByYear,
.menu-container .menuItemRow .menuItemActions {
  border: 1px solid #dedede;
  border-left: 0px;
}

.menu-container:last-of-type .menuItemRow .menuItemType,
.menu-container:last-of-type .menuItemRow .menuItemGroupedByYear,
.menu-container:last-of-type .menuItemRow .menuItemActions {
  border: 1px solid #dedede;
  border-left: 0px;
}

.menu-container:last-of-type .menuItemRow .menuItem {
  border: 1px solid #dedede;
}

.menuItemActions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  width: 12.5%;
}

.menuItemActions button {
  visibility: visible;
  margin: 0px 5px;
  cursor: pointer;

  border: 0;
}

.menuItemActions button {
  background-color:transparent!important;
  color: #97c666!important;
}

button[disabled] {
  visibility: hidden;
}

.trash {
  color: #97c666;
}
</style>
